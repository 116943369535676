<template>
  <Header :title="t('requests')" icon="fa-hand-holding-heart" />
  <Content>
    <table class="relative w-full flex flex-col text-white h-full">
      <div
        class="absolute md:relative w-full mt-6 pb-10 overflow-y-auto h-full "
      >
        <div
          class="w-full flex justify-between md:justify-end mb-3 pl-4 md:pl-0"
        >
          <Checkbox
            :label="t(`showAccepted`)"
            class="w-1/2 md:w-1/3"
            :small="true"
            v-model="showAccepted"
            @change="toggleAcceptedRequests()"
          />
          <Checkbox
            :label="t(`showDeclined`)"
            class="w-1/2 md:w-1/3"
            :small="true"
            v-model="showDeclined"
            @change="toggleDeclinedRequests()"
          />
          <Checkbox
            :label="t(`showDeleted`)"
            class="w-1/2 md:w-1/3"
            :small="true"
            v-model="showDeleted"
            @change="toggleDeletedRequests()"
          />
        </div>
        <template
          v-if="
            (requests.length !== 0 && isEmployee()) ||
              (requests.length !== 0 &&
                selectedUnitUsers.length !== 0 &&
                selectedUnitUsersListLength === selectedUnitUsers.length)
          "
        >
          <div
            v-for="request in requests"
            :key="request.id"
            class="w-full flex flex-col"
          >
            <ChangeTimeItem
              v-if="
                request.data().changeType === 'TimeUpdate' &&
                  ((request.data().accepted && showAccepted) ||
                    (!request.data().accepted &&
                      !request.data().declined &&
                      !request.data().deleted) ||
                    (request.data().declined && showDeclined) ||
                    (request.data().deleted && showDeleted))
              "
              :request="request"
            />
            <NewTimeItem
              v-if="
                request.data().changeType === 'TimeCreate' &&
                  ((request.data().accepted && showAccepted) ||
                    (!request.data().accepted &&
                      !request.data().declined &&
                      !request.data().deleted) ||
                    (request.data().declined && showDeclined) ||
                    (request.data().deleted && showDeleted))
              "
              :request="request"
            />
          </div>
        </template>
      </div>
    </table>
  </Content>
</template>

<script>
import { ref, watchEffect } from "vue";
import Header from "@/components/Header";
import Content from "@/components/Content";
import Checkbox from "@/components/Checkbox.vue";
import ChangeTimeItem from "@/components/TimeRegistration/Requests/ChangeTimeItem";
import NewTimeItem from "@/components/TimeRegistration/Requests/NewTimeItem";
import { isMobile } from "@/global/services/mixins";
import { requests } from "@/global/services/requests";
import { authentication } from "@/global/services/authentication";
import { units } from "@/global/services/units";
import { useI18n } from "vue-i18n";
import { employee } from "@/global/services/employee";

export default {
  name: "TimeRegistration",
  components: {
    Header,
    Content,
    Checkbox,
    ChangeTimeItem,
    NewTimeItem
  },
  setup() {
    const { t, locale } = useI18n();
    const state = {
      requests: requests.getAllRequests(true),
      selectedUnitUsers: units.getSelectedUnitEmployees(true),
      selectedUnitUsersListLength: units.getSelectedUnitEmployeesLength(true),
      oldTime: ref(null),
      showAccepted: ref(false),
      showDeclined: ref(false),
      showDeleted: ref(false)
    };

    const functions = {
      isEmployee: authentication.isEmployee,
      isLeader: authentication.isLeader,
      isOwner: authentication.isOwner,
      toggleAcceptedRequests: function() {
        state.showAccepted.value != state.showAccepted.value;
        if (state.showAccepted.value) {
          authentication.isEmployee()
            ? requests.updateUserAcceptedRequests()
            : requests.updateAcceptedAllRequests();
        }
      },
      toggleDeclinedRequests: function() {
        state.showDeclined.value != state.showDeclined.value;
        if (state.showDeclined.value) {
          authentication.isEmployee()
            ? requests.updateUserDeclinedRequests()
            : requests.updateDeclinedAllRequests();
        }
      },
      toggleDeletedRequests: function() {
        state.showDeleted.value != state.showDeleted.value;
        if (state.showDeleted.value) {
          authentication.isEmployee()
            ? requests.updateUserDeletedRequests()
            : requests.updateDeletedAllRequests();
        }
      }
    };

    watchEffect(() => {
      (authentication.isOwner() || authentication.isLeader()) &&
      state.selectedUnitUsers.value.length !== 0 &&
      units.getSelectedUnitEmployees().length ===
        units.getSelectedUnitEmployeesLength()
        ? requests.updateAllRequests()
        : null;
    });

    watchEffect(() => {
      authentication.isEmployee() &&
      employee.get() &&
      state.requests.value.length === 0
        ? requests.updateUserRequests()
        : null;
    });

    return {
      ...state,
      ...functions,
      isMobile,
      t
    };
  }
};
</script>

<style scoped></style>
