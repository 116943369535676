<template>
  <span class="flex rounded-md justify-center relative">
    <div
      class="w-10/12"
      :class="[small ? 'text-sm text-left' : 'text-base text-center']"
    >
      {{ localTime }}
    </div>
    <span
      v-if="!small"
      class="text-white w-2/12 block rounded-r-md"
      :class="[disabled ? 'bg-green' : 'bg-yellow']"
    >
      <span
        class="datepicker-toggle-button w-full h-full flex items-center justify-center"
      >
        <i class="fas fa-clock"></i>
      </span>
      <input
        v-bind:value="new Date(modelValue).toTimeString().substring(0, 5)"
        @change="$emit('update:modelValue', returnTime($event))"
        type="time"
        class="datepicker-input z-50"
        :disabled="disabled"
        :class="[disabled ? '' : 'pointer']"
      />
    </span>
  </span>
</template>

<script>
import { ref, watchEffect } from "vue";
export default {
  name: "TimePicker",
  emits: ["update:modelValue"],
  props: {
    modelValue: Number,
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const date = new Date(props.modelValue);
    const state = {
      localTime: ref(date.toTimeString())
    };
    watchEffect(() => {
      const newDate = new Date(props.modelValue);
      state.localTime.value = newDate.toTimeString().substring(0, 5);
    });

    const returnTime = event => {
      console.log(event.target.value);
      const time = new Date(props.modelValue);
      time.setHours(parseInt(event.target.value.substring(0, 2)));
      time.setMinutes(parseInt(event.target.value.substring(3, 5)));
      /* console.log(
        time.toISOString(),
        parseInt(event.target.value.substring(0, 2)),
        parseInt(event.target.value.substring(3, 5))
      );
      console.log(time); */
      return time.getTime();
    };
    return { ...state, returnTime };
  }
};
</script>

<style scoped>
.datepicker-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  box-sizing: border-box;
  cursor: pointer;
}
.datepicker-input::-webkit-calendar-picker-indicator {
  z-index: 100;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
</style>
