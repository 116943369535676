<template
  ><tr
    class="hover:my-6 bg-green-dark text-white border-2 border-green-dark w-full flex items-center text-center"
  >
    <td v-if="isOwner() || isLeader()" class="text-sm md:text-base w-1/4">
      {{ formatIdToName(data.requestBy) }}
    </td>
    <td
      :class="[isOwner() || isLeader() ? 'w-1/4' : 'w-1/3']"
      class="text-sm md:text-base"
    >
      {{ t("addingTime") }}
    </td>
    <td
      :class="[isOwner() || isLeader() ? 'w-1/4' : 'w-1/3']"
      class="flex justify-center text-sm md:text-base"
    >
      <p v-if="data.accepted" class="flex flex-col md:flex-row items-center">
        <span class=" w-8 h-8 flex justify-center items-center text-sm">
          <i class="fas fa-check-square"></i> </span
        >{{ t("approvedBy") }} {{ formatIdToName(data.accepted.by) }}
      </p>
      <p v-if="data.declined" class="flex flex-col md:flex-row items-center">
        <span class=" w-8 h-8 flex justify-center items-center text-sm">
          <i class="fas fa-ban"></i> </span
        >{{ t("declinedBy") }} {{ formatIdToName(data.declined.by) }}
      </p>
      <p
        v-if="data.deleted"
        class="flex flex-col md:flex-row items-center text-sm"
      >
        <span class=" w-8 h-8 flex justify-center items-center">
          <i class="fas fa-trash-alt"></i> </span
        >{{ t("deleted") }}
      </p>
      <div
        v-if="!data.deleted && !data.accepted && !data.declined"
        class="flex flex-col md:flex-row"
      >
        <div class="lds-roller md:mr-0 mr-5 -mt-3">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p v-if="!isMobile()" class="md:ml-6 text-xs">{{ t("waiting") }}</p>
      </div>
    </td>
    <td
      v-if="employeeDoc"
      :class="[
        isOwner() || isLeader() ? 'w-1/4 justify-center' : 'w-1/3 justify-end'
      ]"
      class="flex  text-sm md:text-base"
    >
      <span
        v-if="
          !request.data().accepted &&
            !request.data().declined &&
            !request.data().deleted &&
            (isOwner() || isLeader())
        "
        class="transition duration-300 ease-in-out hover:text-green text-yellow flex-shrink-0 cursor-pointer text-xl mr-3 flex justify-center items-center z-10"
        :title="t('acceptRequest')"
        @click.stop="submitAcceptRequest(request.ref, request)"
      >
        <i class="fas fa-check-square"></i>
      </span>
      <span
        v-if="
          !request.data().accepted &&
            !request.data().declined &&
            !request.data().deleted &&
            (isOwner() || isLeader())
        "
        class="transition duration-300 ease-in-out hover:text-green text-red-500 flex-shrink-0 cursor-pointer text-xl mr-3 flex justify-center items-center z-10"
        :title="t('declineRequest')"
        @click.stop="submitDeclineRequest(request.ref)"
      >
        <i class="fas fa-ban"></i>
      </span>
      <span
        v-if="
          !request.data().accepted &&
            !request.data().declined &&
            !request.data().deleted &&
            data.requestBy === employeeDoc.id
        "
        class="transition duration-300 ease-in-out hover:text-green text-red-500 flex-shrink-0 cursor-pointer text-xl mr-3 flex justify-center items-center z-10"
        :title="t('deleteRequest')"
        @click.stop="submitDeleteRequest(request.id)"
      >
        <i class="fas fa-trash-alt"></i>
      </span>
    </td>
  </tr>
  <tr
    class="bg-green-darkest text-green border-2 border-green-dark mb-6 py-3 w-full flex items-center justify-between"
  >
    <td class="w-1/4 text-center text-green text-sm md:text-base">
      <p>{{ t("newTime") }}</p>
    </td>
    <td class="w-1/4">
      <DatePicker
        :disabled="true"
        v-model="data.data.start"
        class="text-green-darkest bg-white"
        :small="true"
      />
    </td>
    <td class="w-1/4 flex justify-center">
      <TimePicker :disabled="true" v-model="data.data.start" :small="true" />
    </td>
    <td class="w-1/4 flex justify-center">
      <TimePicker :disabled="true" v-model="data.data.end" :small="true" />
    </td>
  </tr>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import TimePicker from "@/components/TimePicker";
import { ref } from "vue";
import { authentication } from "@/global/services/authentication";
import { systemState } from "@/global/services/systemState";
import { errorLogging } from "@/global/services/errorLogging";
import { isMobile } from "@/global/services/mixins";
import { requests } from "@/global/services/requests";
import { employee } from "@/global/services/employee";

import { useI18n } from "vue-i18n";

export default {
  name: "NewTimeItem",
  components: {
    DatePicker,
    TimePicker
  },
  props: {
    request: Object
  },
  setup(props) {
    const { t } = useI18n({
      messages: {
        en: {
          addingTime: "Adding new time",
          deleted: "Deleted",
          waiting: "Awaiting approval",
          acceptRequest: "Accept Request",
          declineRequest: "Decline Request",
          deleteRequest: "Delete request",
          newTime: "New time:",
          approvedBy: "Approved by:",
          declinedBy: "Declined by:"
        },
        dk: {
          addingTime: "Tilføjelse af ny tid",
          deleted: "Slettet",
          waiting: "Afventer godkendelse",
          acceptRequest: "Godkend anmodning",
          declineRequest: "Afvis anmodning",
          deleteRequest: "Slet anmodning",
          newTime: "Ny tid:",
          approvedBy: "Godkendt af:",
          declinedBy: "Afvist af:"
        }
      }
    });
    const state = {
      employeeDoc: employee.get(true),

      data: ref(props.request.data())
    };

    const functions = {
      isMobile,
      isOwner: authentication.isOwner,
      isLeader: authentication.isLeader,
      isEmployee: authentication.isEmployee,

      formatIdToName: employee.formatIdToName,

      formatDuration: function(dt1, dt2) {
        if (dt2 === false) {
          return "-- : --";
        }
        const date1 = new Date(parseInt(dt1));
        const date2 = new Date(parseInt(dt2));
        const diff = (date2.getTime() - date1.getTime()) / 1000;
        const diffMin = diff / 60;
        const minutes = Math.floor(diffMin % 60);
        const hours = Math.floor(diffMin / 60);
        return `${hours}:${minutes}`;
      },

      formatTime: function(time) {
        if (time === false) {
          return "-- : --";
        }
        const date = new Date(parseInt(time));
        const string = `${date.getHours()} : ${date.getMinutes()}`;
        return string;
      },

      formatRequestDate: function(dateObject) {
        const year = dateObject.getFullYear().toString();
        let month = (dateObject.getMonth() + 1).toString();
        let date = dateObject.getDate().toString();
        month.length === 1 ? (month = "0" + month) : month;
        date.length === 1 ? (date = "0" + date) : date;
        const formattetString = date + " - " + month + " - " + year.slice(2, 4);
        return formattetString;
      },

      submitDeleteRequest: function(requestId) {
        systemState.startProcessing();
        requests.deleteRequest(requestId).then(message => {
          errorLogging.setSuccess(message);
          systemState.stopProcessing();
        });
      },

      submitAcceptRequest: function(requestRef, requestSnap) {
        systemState.startProcessing();
        requests
          .acceptRequest(requestRef, requestSnap)
          .then(message => {
            systemState.stopProcessing();
            errorLogging.setSuccess(message);
          })
          .catch(message => {
            systemState.stopProcessing();
            errorLogging.setError(message);
          });
      },

      submitDeclineRequest: function(requestId) {
        systemState.startProcessing();
        requests
          .declineRequest(requestId)
          .then(message => {
            systemState.stopProcessing();
            errorLogging.setSuccess(message);
          })
          .catch(message => {
            systemState.stopProcessing();
            errorLogging.setError(message);
          });
      }
    };

    return { t, ...state, ...functions };
  }
};
</script>

<style scoped>
.lds-roller {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  transform: scale(0.3);
}
.lds-roller div {
  animation: lds-roller 2.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #f5ae00;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
